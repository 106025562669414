var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("div", [
        _c("h2", [
          _vm._v("\n      " + _vm._s(_vm.$t("Users.title")) + "\n    ")
        ])
      ]),
      _c("UserRoleTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }