<template>
  <v-container
    fluid
  >
    <div>
      <h2>
        {{ $t('Users.title') }}
      </h2>
    </div>
    <UserRoleTable />
  </v-container>
</template>

<script>
import UserRoleTable from '@/components/permissions/UserRoleTable';

export default {
  name: 'Users',
  components: {
    UserRoleTable
  }
};
</script>

<style scoped lang="scss">
  h2 {
    font-weight: bolder;
  }
</style>
